<template>
  <div class="security">
    <h1>Trusted IP Addresses</h1>
    <div class="ip-form">
      <input v-model="newIP" type="text" placeholder="Add new IP address" />
      <button @click="addIP">Add</button>
    </div>
    <p v-if="validationError" class="error">{{ validationError }}</p>
    <br>
    <br>
    <hr>
    <div class="ip-list">
      <div v-for="(ip, index) in ipList" :key="index" class="ip-item">
        <span>{{ ip }}</span>
        <button @click="deleteIP(index)">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "security",
  data() {
    return {
      newIP: "",
      ipList: ["192.168.1.1", "10.0.0.1"], // Replace this with your data source or an empty array
      validationError: "",
    };
  },
  methods: {
    addIP() {
      if (this.isValidIP(this.newIP.trim())) {
        this.ipList.push(this.newIP.trim());
        this.newIP = "";
        this.validationError = ""; // Clear any previous error
      } else {
        this.validationError = "Invalid IP address. Please enter a valid IP.";
      }
    },
    deleteIP(index) {
      this.ipList.splice(index, 1);
    },
    isValidIP(ip) {
      // Regular expression to validate IP address
      const ipPattern =
        /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      return ipPattern.test(ip);
    },
  },
};
</script>

<style scoped>
.security {
  padding: 20px;
}

.security h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.ip-form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ip-form input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ip-form button {
  margin-left: 10px;
  background-color: var(--va-primary);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.ip-list .ip-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ip-list .ip-item span {
  flex: 1;
  font-size: 16px;
}

.ip-list .ip-item button {
  background-color: var(--va-danger);
  color: white;
  border: none;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
}
.error {
  color: red;
  margin-top: 5px;
}
</style>
